<template>
  <v-container fluid>
    <v-container>
      <!-- ÁRAJÁNLAT ELFOGADÁSA / ELUTASÍTÁSA START -->
      <!--<v-row v-if="hasActiveQuote">-->
      <v-row v-if="serviceRequest.currentStatus === 30">
        <v-col cols="12">
          <v-card class="fill-height">
            <v-card-title class="amber lighten-2">
              <v-icon color="black" class="mr-4">mdi-alert-decagram-outline</v-icon>
              <span class="subtitle-1">{{ $t('quoteDone') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'px-3 pt-10' : 'px-6 pt-10'">
              <div class="text-center">
                <h1 class="font-weight-regular mb-6">{{ $t('hasActiveQuoteBox.title')}}</h1>
                <p>{{ $t('hasActiveQuoteBox.desc')}}</p>
                <p>{{ $t('hasActiveQuoteBox.dowloadSentenceText') }} <span class="font-weight-bold" @click="downloadFile(quoteDoc)" style="cursor: pointer;">{{ $t('hasActiveQuoteBox.dowloadSentenceLinkText') }}</span>.</p>
              </div>
            </v-card-text>
            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row justify="center" class="mx-0">
                <v-btn class="success" @click="quoteAccept">{{ $t('button.quoteAcceptText') }}</v-btn>
                <v-btn class="error" @click="quoteReject" :class="$vuetify.breakpoint.xsOnly ? 'mb-4' : 'ml-6'">{{ $t('button.quoteRejectText') }}</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!-- ÁRAJÁNLAT ELFOGADÁSA / ELUTASÍTÁSA END -->
      <!-- ONLINE FIZETÉS START -->
      <template v-if="(onlinePaymentEnabled && serviceRequest.currentStatus >= 50) && serviceRequest.serviceRequest.invoiceCreatedAt">
        <v-row>
          <v-col cols="12">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-credit-card-outline</v-icon>
                <span class="subtitle-1">{{ $t('button.onlinePayment') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-5'">
                <v-row>
                  <v-col cols="12">
                    <p>Kedves Ügyfelünk!<br>A <b>{{ serviceRequest.serviceRequest.id }}</b> azonosítójú javítási igényhez tartozó számlája elkészült.</p>
                  </v-col>
                  <v-col cols="4" md="3" class="text-center">
                    <div class="mb-4">
                      {{ $t('invoiceNumber') }}
                    </div>
                    <div class="font-22">
                      {{ serviceRequest.serviceRequest.dolphinInvoiceId }}
                    </div>
                  </v-col>
                  <v-col cols="4" md="3" class="text-center">
                    <div class="mb-4">
                      {{ $t('invoiceCreatedAt') }}
                    </div>
                    <div class="font-22">
                      {{ serviceRequest.serviceRequest.invoiceCreatedAt.substr(0, 10) }}
                    </div>
                  </v-col>
                  <v-col cols="4" md="3" class="text-center">
                    <div class="mb-4">
                      {{ $t('invoiceGrossPrice') }}
                    </div>
                    <div class="font-22 font-weight-bold">
                      {{ serviceRequest.serviceRequest.grossPrice }} Ft
                    </div>
                  </v-col>
                  <v-col cols="4" md="3" class="text-center">
                    <div class="mb-4">
                      {{ $t('paymentMethod') }}
                    </div>
                    <v-radio-group v-model="paymentProvider" hide-details>
                      <v-row justify="center">
                        <v-col cols="6" class="pt-0" v-if="payPalEnabled">
                          <v-card :class="paymentProvider === 1 ? 'primary-border py-4' : 'transparent-border py-4'"
                                  @click="paymentProvider = 1">
                            <v-img :src="`${publicPath}images/pp-logo-1@64x64.png`"
                                   class="mx-auto"
                                   height="32"
                                   width="32"></v-img>
                            <div class="text-center mt-3">
                              PayPal
                            </div>
                          </v-card>
                          <div class="paymentMethod-radio">
                            <v-radio :value="1"></v-radio>
                          </div>
                        </v-col>
                        <v-col cols="6" class="pt-0" v-if="creditCardEnabled">
                          <v-card :class="paymentProvider === 2 ? 'primary-border py-4' : 'transparent-border py-4'"
                                  @click="paymentProvider = 2">
                            <div class="text-center">
                              <v-icon color="primary" size="32">
                                mdi-credit-card-outline
                              </v-icon>
                            </div>
                            <div class="text-center mt-3">
                              Bankkártya
                            </div>
                          </v-card>
                          <div class="paymentMethod-radio">
                            <v-radio :value="2"></v-radio>
                          </div>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-5'">
                <v-spacer></v-spacer>
                <v-btn text color="primary">
                  {{ $t('button.downloadInvoice') }}
                </v-btn>
                <v-btn color="primary"
                       :class="$vuetify.breakpoint.xsOnly ? 'ma-0' : 'ml-5'"
                       :disabled="!paymentProvider"
                       @click="createOrder">
                  {{ $t('button.onlinePayment') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <!-- ONLINE FIZETÉS END -->
      <v-row>
        <!-- KÖVETÉS START -->
        <v-col cols="12" md="6" lg="4">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-timeline-text-outline</v-icon>
              <span class="subtitle-1">{{ $t('tracking') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-list two-line class="py-0">
                <!-- KÖVETÉS START -->
                <template v-for="(item, index) in serviceRequest.history">
                  <v-list-item :key="index" v-show="item.code !== 40 && item.code !== 99 && item.code !== 80 && item.code !== 70">
                    <v-list-item-avatar>
                      <v-icon :color="item.date ? 'success' : 'grey lighten-1'">
                        {{ item.date ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title :class="item.date ? 'font-weight-bold' : 'font-weight-bold grey--text lighten-1'">
                        {{ item.status }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.dateToDisplay }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.date && (item.documents && item.documents.length > 0)">
                      <v-tooltip top v-for="(item, index) in item.documents" :key="index">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on" @click="downloadFile(item.status)">
                            mdi-file-download-outline
                          </v-icon>
                        </template>
                        <span>
                          {{ item.name }} letöltése
                        </span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
                <!-- KÖVETÉS END -->
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- KÖVETÉS END -->
        <!-- RÉSZLETEK START -->
        <v-col cols="12" md="6" lg="8">
          <!-- IGÉNY ADATOK START -->
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
              <span class="subtitle-1">{{ $t('request.requestData') }}</span>
              <v-spacer></v-spacer>
              <v-btn v-if="showServiceWarrantyBtn === 'igen'"
                     outlined
                     color="success"
                     @click="goToswPickupPage">
                {{ $t('swPickup') }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequest.serviceRequest.id"
                                    :label="$t('serviceRequestId')"
                                    filled
                                    hide-details
                                    disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="$store.state.authModule.user && $store.state.authModule.user.profile.customerType === 'EMPLOYEE'">
                      <v-text-field v-model="serviceRequest.serviceRequest.gsmWorksheetId"
                                    :label="$t('gsmWorksheetId')"
                                    filled
                                    hide-details
                                    disabled>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select v-model="serviceRequest.serviceRequest.warrantyType"
                            :items="$store.state.selectfields.warrantyType"
                            item-text="name"
                            item-value="id"
                            :label="$t('inputLabels.warrantyType')"
                            filled
                            hide-details
                            disabled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      v-model="serviceRequest.serviceRequest.purchaseDate"
                      :label="$t('inputLabels.dateOfPurchase')"
                      filled
                      hide-details
                      disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.brand.name"
                                :label="$t('inputLabels.manufacturer')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.productType.tradeName"
                                :label="$t('inputLabels.productType')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      v-model="serviceRequest.serviceRequest.networkLock.name"
                      :label="$t('inputLabels.simLock')"
                      filled
                      hide-details
                      disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn1"
                                :label="$t('inputLabels.uniqueIdentifierIn1new')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn2"
                                :label="$t('inputLabels.uniqueIdentifierIn2new')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn3"
                                :label="$t('inputLabels.uniqueIdentifierIn3new')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="serviceRequest.serviceRequest.errorDescription"
                              :label="$t('inputLabels.troubleDescription')"
                              filled
                              hide-details
                              auto-grow
                              disabled>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- IGÉNY ADATOK END -->
        </v-col>
        <!-- RÉSZLETEK END -->
      </v-row>
      <!-- ÜGYFÉL ADATOK START -->
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
              <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" sm="6" md="5" lg="4">
                  <v-text-field v-model="customerNameString"
                                :label="$t('contactName')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="4">
                  <v-text-field v-model="serviceRequest.customer.phone"
                                :label="$t('phoneNumber')"
                                prefix="+36/"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <v-text-field v-model="serviceRequest.customer.email"
                                :label="$t('email')"
                                filled
                                hide-details
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="py-2">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="shippingAddressString"
                                :label="$t('shippingAddress')"
                                filled
                              rows="1"
                              auto-grow
                                hide-details
                                disabled>
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="billingAddressString"
                              :label="$t('invoicingAddress')"
                              filled
                              rows="1"
                              auto-grow
                              hide-details
                              disabled>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- ÜGYFÉL ADATOK END -->
      <!-- DOKUMENTUMOK START -->
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-file-document-outline</v-icon>
              <span class="subtitle-1">{{ $t('documents') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <!-- DOKUMENTUMOK LISTÁZÁSA ÚJ -->
                <template v-for="(document, index) in serviceRequest.documents">
                  <v-col col="12" sm="4" :key="index" v-show="document.hash && document.table">
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-avatar color="primary">
                                <v-icon color="white">
                                  mdi-pdf-box
                                </v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="">
                                {{ document.description }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ document.createdAt }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                      <v-card-actions class="px-3">
                        <v-row justify="end" class="ma-0">
                          <v-btn text color="primary" @click="downloadFile(document)">
                            {{ $t('button.download') }}
                          </v-btn>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
                <template v-if="serviceRequest.returnReport">
                  <v-col col="12" sm="4">
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-avatar color="primary">
                                <v-icon color="white">
                                  mdi-pdf-box
                                </v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="">
                                {{ $t('returnWorksheetDocument') }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                &nbsp;
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                      <v-card-actions class="px-3">
                        <v-row justify="end" class="ma-0">
                          <v-btn text color="primary" @click="callShowReturnWorksheetDocument(serviceRequest.gsmWorksheetNumber, serviceRequest.returnWorksheetDocumentId, true)">
                            {{ $t('button.download') }}
                          </v-btn>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- DOKUMENTUMOK END -->
      <!-- v-row>
        <v-col cols="6">
          <v-btn color="primary"
                 @click="loghubDialog = true">
            LOGHUB REQUEST DATA
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn color="primary"
                 @click="loghubDialogDelivery = true">
            LOGHUB delivery notes
          </v-btn>
        </v-col>
      </v-row-->
    </v-container>
    <document-uploader :crm-request-id="serviceRequest.serviceRequest.id" :is-visible="isUploadCardVisible"></document-uploader>
    <v-dialog v-model="downloadFileErrorDialog.visible" max-width="440px">
      <v-card>
        <v-card-title>
          <v-icon color="error" class="mr-4">mdi-alert</v-icon>
          <span class="subtitle-1">{{ $t('downloadFileErrorDialog.title') }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <div class="subtitle-1">
            {{ $t('downloadFileErrorDialog.noFile') }}
          </div>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="downloadFileErrorDialog.visible = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- v-dialog v-model="loghubDialog" max-width="600">
      <v-card>
        <v-card-text>
          <v-list>
            <template v-for="(item, index) in serviceRequest.loghubRD">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.requestDataName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.requestDataDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary">
                    mdi-eye
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loghubDialogDelivery" max-width="1200">
      <v-card>
        <v-card-text>
          <v-data-table
            :locale="$i18n.locale"
            :headers="deliveryNoteHeader"
            :items="serviceRequest.loghubDN"
            :sort-by="['deliveryNoteStatusDate']"
            :loading-text="$t('loadingText')"
            :no-data-text="$t('noDataText.noRequestFound')">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog-->
  </v-container>
</template>

<script>
import DocumentUploader from "./DocumentUploader.vue";
import {
  ONLINE_PAYMENT_ENABLED,
  PP_ENABLED,
  CREDIT_CARD_ENABLED,
  LOCATION } from 'src/utils/systemParameters';

export default {
  name: 'RequestDetailsFizetos',
  components: {
    DocumentUploader,
  },
  props: {
    serviceRequest: {
      type: Object,
    }
  },
  async mounted() {
    this.productString = `${this.serviceRequest.serviceRequest.brand.name} ${this.serviceRequest.serviceRequest.productType.tradeName}`;
    this.customerNameString = `${this.serviceRequest.customer.lastName} ${this.serviceRequest.customer.firstName}`;
    this.shippingAddressString = `${this.serviceRequest.shippingAddressData.zipCode} ${this.serviceRequest.shippingAddressData.city}, ${this.serviceRequest.shippingAddressData.publicPlaceName} ${this.serviceRequest.shippingAddressData.publicPlaceType} ${this.serviceRequest.shippingAddressData.houseNumber}`;
    this.billingAddressString = `${this.serviceRequest.billingAddressData.billingName} - ${this.serviceRequest.billingAddressData.zipCode} ${this.serviceRequest.billingAddressData.city}, ${this.serviceRequest.billingAddressData.publicPlaceName} ${this.serviceRequest.billingAddressData.publicPlaceType} ${this.serviceRequest.billingAddressData.houseNumber}`;
    this.hasActiveQuote = this.checkHasActiveQuote();
    this.checkIsUploadCardVisible();
    if (this.serviceRequest.currentStatus === 30) {
      this.quoteDoc = this.findQuote();
    }
    const resp = await this.$store.dispatch('ServiceWarrantyRulesFits', this.serviceRequest.serviceRequest.id);
    this.showServiceWarrantyBtn = resp.data.serviceWarranty;
    // this.showServiceWarrantyBtn = 'nem';
  },
  data() {
    return {
      productString: '',
      customerNameString: '',
      shippingAddressString: '',
      billingAddressString: '',
      hasActiveQuote: false,
      isUploadCardVisible: false,
      quoteDoc: {},
      downloadFileErrorDialog: {
        visible: false,
        message: 'downloadFileErrorDialog.noFile',
      },
      paymentProvider: null,
      onlinePaymentEnabled: ONLINE_PAYMENT_ENABLED,
      payPalEnabled: PP_ENABLED,
      creditCardEnabled: CREDIT_CARD_ENABLED,
      loghubDialog: false,
      loghubDialogDelivery: false,
      deliveryNoteHeader: [
        { text: 'deliveryNoteId', value: 'deliveryNoteId' },
        { text: 'deliveryNoteName', value: 'deliveryNoteName' },
        { text: 'deliveryNoteNumber', value: 'deliveryNoteNumber' },
        { text: 'deliveryNoteStatusDate', value: 'deliveryNoteStatusDate' },
        { text: 'deliveryNoteStatusId', value: 'deliveryNoteStatusId' },
        { text: 'deliveryNoteStatusName', value: 'deliveryNoteStatusName' },
        { text: 'requestDataId', value: 'requestDataId' },
      ],
      showServiceWarrantyBtn: 'nem',
    };
  },
  methods: {
    async downloadFile(document) {
      this.$emit('open-loading-dialog');
      // console.log('document: ', document);
      if (document !== {}) {
        let fileHandler = {
          crmRequestId: this.serviceRequest.serviceRequest.id,
          table: document.table,
          hash: document.hash,
        }
        const response = await this.$store.dispatch('downloadDocument', { form: fileHandler });
        if (response.status === this.$HTTP_OK) {
          this.$emit('close-loading-dialog', { status: 200 });
        } else {
          const message = response.message ? response.message : 'A keresett fájl nem létezik.';
          const htmlMessage = `<p class="text-center">${message}</p>`;
          this.$emit('close-loading-dialog',
            {
              status: response.status,
              title: 'Hiba a fájl letöltésekor',
              message: htmlMessage,
            });
        }
      } else {
        this.downloadFileErrorDialog.visible = true;
      }
    },
    findQuote() {
      let file = this.serviceRequest.documents.find((item) => {
        return item.description === 'Árajánlat'
      });
      return file || {};
    },
    quoteAccept() {
      this.$emit('quote-accept');
    },
    quoteReject() {
      this.$emit('quote-reject');
    },
    checkHasActiveQuote() {
      if (this.serviceRequest.documents) {
        let ajanlat = this.serviceRequest.history.find(item => item.code === 30);
        // console.log('ajanlat: ', ajanlat);
        let elfogadott = this.serviceRequest.history.find(item => item.code === 32);
        if(!elfogadott) {
          elfogadott = {date: null};
        }
        let elutasitott = this.serviceRequest.history.find(item => item.code === 34);
        if(!elutasitott) {
          elutasitott = {date: null};
        }
        let nincsvalasz = this.serviceRequest.history.find(item => item.code === 36);
        if(!nincsvalasz) {
          nincsvalasz = {date: null};
        }
        if(nincsvalasz.date || elutasitott.date || elfogadott.date) {
          return false;
        } else if(ajanlat.date) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      };
    },
    checkIsUploadCardVisible() {
      const item = this.serviceRequest.history.findIndex(x => x.code === 5 && x.date);
      // console.log('checkIsUploadCardVisible item: ', item);
      if (item > 0) {
        this.isUploadCardVisible = false;
      } else {
        this.isUploadCardVisible = true;
      }
    },
    async createOrder() {
      const order = {
        crmRequestId: this.serviceRequest.serviceRequest.id,
        paymentProvider: 'paypal',
        successUrl: `${LOCATION}/sikeres-fizetes`,
        cancelUrl: `${LOCATION}/visszavont-fizetes`,
        failureUrl: `${LOCATION}/sikertelen-fizetes`,
        totalPrice: this.serviceRequest.serviceRequest.grossPrice,
      };
      const response = await this.$store.dispatch('createPPOrder', order);
      // console.log('createOrder response: ', response);
      if (response.status === 201) {
        window.open(`${response.data.redirectUrl}`, '_self');
      }
    },
    goToswPickupPage() {
      if (this.$route.meta.layout === 'outer') {
        this.$router.push({ name: 'szervizgarancia-futarszolgaltatas', params: { identicalHash: this.$route.params.vs } });
      } else {
        // this.$router.push({ name: 'szervizgarancia-belepett', params: { crmRequestId: this.$route.params.crmRequestId } });
      }
    },
    async callShowReturnWorksheetDocument(gsmWorksheetId, documentId, openInBrowser = false) {
      this.$emit('open-loading-dialog');
      const response = await this.$store.dispatch('gsmApi/ShowReturnWorksheetDocument',
        {
          gsmWorksheetId: gsmWorksheetId,
          documentId: documentId
        });
      if (response.status === this.$HTTP_OK) {
        await this.$store.dispatch('downloadManager/fileDecoder',
          {
            response: response,
            openInBrowser: openInBrowser
          });
        this.$emit('close-loading-dialog', { status: 200 });
      } else {
        const message = response.message ? response.message : 'A keresett fájl nem létezik.';
        const htmlMessage = `<p class="text-center">${message}</p>`;
        this.$emit('close-loading-dialog',
          {
            status: response.status,
            title: 'Hiba a fájl letöltésekor',
            message: htmlMessage,
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
