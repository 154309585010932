<template>
  <div v-frag>
    <v-container v-show="isVisible">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-file-upload-outline</v-icon>
              <span class="subtitle-1">{{ $t('documentUpload') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" md="6">
                  <v-file-input v-model="attachment.invoice"
                                :label="$t('inputLabels.invoice')"
                                accept=".pdf"
                                counter
                                show-size
                                filled
                                ref="valami">
                    <template v-slot:selection="{text}">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input v-model="attachment.warrantyCard"
                                :label="$t('inputLabels.warrantyTicket')"
                                accept=".pdf"
                                counter
                                show-size
                                filled>
                    <template v-slot:selection="{text}">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="primary" :disabled="!documentFormIsValid" @click="uploadDocuments">
                {{ $t('button.upload') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- LOADING, RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
    <!-- LOADING, RESPONSE DIALOGS END -->
  </div>
</template>

<script>
import LoadingDialog from "../dialogs/LoadingDialog.vue";
import ResponseDialog from "../dialogs/ResponseDialog.vue";
import { getUid, getToken } from "../../utils/jwtHelper";

export default {
  name: 'DocumentUploader',
  components: {
    LoadingDialog, ResponseDialog,
  },
  props: {
    crmRequestId: {
      type: Number,
      default: 9999999999999999999999999999999999999999,
    },
    isVisible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      attachment: {
        invoice: null,
        warrantyCard: null,
      },
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_DOCUPLOADING',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
    };
  },
  methods: {
    async uploadDocuments() {
      this.openLoadingDialog();
      const response = await this.$store.dispatch('uploadDocument',
        { form: { crmRequestId: this.crmRequestId, sender: 'customer' }, attachment: this.attachment });
      // console.log('uploadDocuments response: ', response);
      if (response === 200) {
        this.responseDialog.response.message = `<div class="display-1 mb-6"><b>Sikeres feltöltés!</b></div><div class="headline mb-4">A dokumentumok feltöltése sikeres volt.</div><div>Köszönjük megkeresését!</div>`;
      } else if(response === 404) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>Váratlan hiba történt!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Kérjük próbálja meg a feltöltést újra!</div><div>Köszönjük türelmét!</div>';
      } else {
        this.responseDialog.response.message = this.ERROR_MESSAGES.FORM_FAIL_MESSAGE;
      }
      this.controlDialogs();
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      window.location.reload();
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
  },
  computed: {
    documentFormIsValid() {
      return (this.attachment.invoice || this.attachment.warrantyCard);
    }
  }
};
</script>

<style scoped>

</style>
