import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c(VContainer,{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}]},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-file-upload-outline")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t('documentUpload')))])],1),_c(VDivider),_c(VCardText,{class:_vm.$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VFileInput,{ref:"valami",attrs:{"label":_vm.$t('inputLabels.invoice'),"accept":".pdf","counter":"","show-size":"","filled":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c(VChip,{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.attachment.invoice),callback:function ($$v) {_vm.$set(_vm.attachment, "invoice", $$v)},expression:"attachment.invoice"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VFileInput,{attrs:{"label":_vm.$t('inputLabels.warrantyTicket'),"accept":".pdf","counter":"","show-size":"","filled":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c(VChip,{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.attachment.warrantyCard),callback:function ($$v) {_vm.$set(_vm.attachment, "warrantyCard", $$v)},expression:"attachment.warrantyCard"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"primary",attrs:{"disabled":!_vm.documentFormIsValid},on:{"click":_vm.uploadDocuments}},[_vm._v(" "+_vm._s(_vm.$t('button.upload'))+" ")])],1)],1)],1)],1)],1),_c(VDialog,{attrs:{"width":"90%","max-width":"340","persistent":true},model:{value:(_vm.loadingDialog.visible),callback:function ($$v) {_vm.$set(_vm.loadingDialog, "visible", $$v)},expression:"loadingDialog.visible"}},[_c('loading-dialog',{attrs:{"loading-message":_vm.loadingDialog.message}})],1),_c(VDialog,{attrs:{"width":"90%","max-width":"340","persistent":true},model:{value:(_vm.responseDialog.visible),callback:function ($$v) {_vm.$set(_vm.responseDialog, "visible", $$v)},expression:"responseDialog.visible"}},[_c('response-dialog',{attrs:{"response-object":_vm.responseDialog.response},on:{"close-dialog":_vm.closeResponseDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }